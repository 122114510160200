import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import VueMoment from "vue-moment";
import moment from "moment-timezone";
import firebase from "firebase";
import mwutils from "@/assets/utilities.js";
import mwsurveyutils from "@/assets/surveyUtilities.js";
import VueI18n from "vue-i18n";
import english from "@/assets/translations/english.js";
import IdleVue from "idle-vue";
// import * as Sentry from "@sentry/vue";
// import { Integrations } from "@sentry/tracing";

Vue.config.productionTip = false;

Vue.prototype.mwutils = mwutils;
Vue.prototype.mwsurveyutils = mwsurveyutils;

// Sentry.init({
// 	Vue,
// 	dsn:
// 		"https://5d7474e8127d4204b2a44c07a196fa66@o397452.ingest.sentry.io/5901981",
// 	integrations: [
// 		new Integrations.BrowserTracing({
// 			routingInstrumentation: Sentry.vueRouterInstrumentation(router),
// 			tracingOrigins: ["localhost", "my-site-url.com", /^\//],
// 		}),
// 	],
// 	// Set tracesSampleRate to 1.0 to capture 100%
// 	// of transactions for performance monitoring.
// 	// We recommend adjusting this value in production
// 	tracesSampleRate: 1.0,
// });

Vue.use(VueMoment, {
	moment,
});

Vue.use(VueI18n);

const i18n = new VueI18n({
	locale: "en",
	messages: {
		en: english,
	},
});

var firebaseConfig = {
	apiKey: "AIzaSyBdW6UBnyaDRc6amjZkkGXOcURzfBM2-iE",
	authDomain: "dma-amb.firebaseapp.com",
	projectId: "dma-amb",
	storageBucket: "dma-amb.appspot.com",
	messagingSenderId: "71045371376",
	appId: "1:71045371376:web:39b0fec8c500207c91f935",
	measurementId: "G-EJ6B0PN403",
};

firebase.initializeApp(firebaseConfig);
firebase.getCurrentUser = () => {
	return new Promise((resolve, reject) => {
		const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
			unsubscribe();
			resolve(user);
		}, reject);
	});
};

firebase.auth().onAuthStateChanged((user) => {
	if (user) {
		store.dispatch("auth/start");
		// .catch(() => {
		// 	router.replace("/error");
		// });
	}
});

Vue.use(IdleVue, {
	store,
	idleTime: 1200000,
});

new Vue({
	i18n,
	router,
	store,
	vuetify,
	render: (h) => h(App),
}).$mount("#app");
