export default {
	firestorePath: "questions",
	firestoreRefType: "collection",
	moduleName: "questions",
	statePropName: "data",
	namespaced: true,
	serverChange: {
		addedHook: function(updateStore, doc, _, { dispatch }) {
			if( doc.question_template ){
				dispatch("questionTemplates/fetchById", doc.question_template, {root: true});
			}
			if( doc.indicator ){
				dispatch("indicators/fetchById", doc.indicator, {root: true});
			}
            return updateStore(doc)
		},
	},
	state: {},
	getters: {
		getQuestionTexts(state, _, rootState ){
			return (id, staffSurvey) => {
				let questionText = {
					indicator: "", 
					templateTitle: "", 
					content: ""
				};
				let question = state.data[id];
				if( question ){
					if( question.content ){
						questionText.content = question.content;
					}
					let indicator = rootState.indicators.data[question.indicator];
					if (indicator) {
						questionText.indicator = staffSurvey ? indicator.staff_survey_title : indicator.question_title;
					}					
					let questionTemplate = rootState.questionTemplates.data[question.question_template];
					if (questionTemplate) {
						questionText.templateTitle = questionTemplate.title;
					}					
				}
				questionText.string = `${questionText.templateTitle} ${questionText.content} ${questionText.indicator}`;
				return questionText;
			} 
		},		
		getQuestionText(state, _, rootState ){
			return (id) => {
				let questionText = "";
				let question = state.data[id];
				if( question ){
					if( question.content ){
						questionText +=  `<p class="title">${question.content}</p>`;
					}
					let indicator = rootState.indicators.data[question.indicator];
					if (indicator) {
						questionText += indicator.question_title;
					}					
				}
				return questionText;
			} 
		},
		getAnswerOptions(state, _, rootState ){
			return (id) => {
				let question = state.data[id];
				if( !question || !question.question_template ){
					return false;
				}
				return rootState.questionTemplates.data[question.question_template] ? rootState.questionTemplates.data[question.question_template].answer_options : "";
			}
		}
	},
	mutations: {},
	actions: {},
};
