import mwutils from "@/assets/utilities.js";
export default {
	firestorePath: "config/{id}",
	firestoreRefType: "doc",
	moduleName: "config",
	statePropName: "data",
	namespaced: true,
	serverChange: {
		modifiedHook: function(updateStore, doc, _, { dispatch }) {
			dispatch("sections/fetchByIds", doc.sections, { root: true });
			return updateStore(doc);
		},
	},
	state: {},
	getters: {
		assessmentTitle(){
			return "AACE Digital Maturity Assessment"
		}
	},
	mutations: {},
	actions: {
		checkRequirements({ state, rootState, dispatch }) {
			dispatch;
			let config = state.data;
			let sections = config.sections;
			let assessment = rootState.assessment.data;
			let organisation = rootState.organisation.data;
			let create = [];
			sections.forEach((id) => {
				if (!assessment.responses[id]) {
					if ((!config.rules[id] || config.rules[id].length == 0 )&& (!config.multiple[id] || config.multiple[id].length == 0 )) {
						create.push({ section: id, multiple: false });
					}
					if (config.rules[id] && !config.multiple[id]) {
						var passed = false;
						config.rules[id].forEach((rule) => {
							if (mwutils.testRule(rule, organisation)) {
								passed = true;
							}
						});
						if (passed) {
							create.push({ section: id, multiple: false });
						}
					}
				}

				if (config.multiple[id]) {
					config.multiple[id].forEach((rule) => {
						if (
							!assessment.responses[id] ||
							!assessment.responses[id][rule.value]
						) {
							if (mwutils.testRule(rule, organisation)) {
								create.push({
									section: id,
									multiple: true,
									[rule.category]: rule.value,
									rule: rule.category
								});
							}
						}
					});
				}

			});
			create.forEach(( insert) => {
				insert.assessment = assessment.id;
				let rule = insert.rule;
				delete( insert.rule )
				dispatch("sectionResponses/insert", insert, {
					root: true,
				}).then((response) => {
					var patch = { [insert.section]: response };
					if( insert.multiple ){
						patch = { [insert.section]: {[insert[rule]]: response } }
					}
					dispatch(
						"assessment/patch",	{
							responses: patch,
						},
						{ root: true }
					);
				});
			});
		},
		start({ dispatch, rootGetters }, id) {
			dispatch("openDBChannel", { pathVariables: { id } }).then(() => {
				if (rootGetters.isLead) {
					dispatch("checkRequirements");
				}
			});
		},
	},
};

// let config = rootState.config.data;
// console.log( Object.keys(config), config )

// 	dispatch("createSection");
