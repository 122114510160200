<template>
	<div>
		<nav-item  :expanded="expanded"  link="/organisation-profile" icon="mdi-hospital-building" :text="$t('organisation.profile')">
			<template v-slot:append>
				<v-list-item-action v-if="multipleOrganisations">
					<v-btn icon>
						<v-icon>mdi-swap-horizontal-circle</v-icon>
					</v-btn>
				</v-list-item-action>
			</template>
		</nav-item>
		<nav-item  :expanded="expanded"  link="/my-team/" icon="mdi-account-group" :text="$t('collaboration.my_team')"></nav-item>
		<nav-item :expanded="expanded" link="/staff-survey-dashboard/" icon="mdi-clipboard-edit-outline" :text="$t('staff_survey.title')"></nav-item>
		<results-navigation  :expanded="expanded"  v-if="allComplete"></results-navigation>
	</div>
</template>
<script>
import NavItem from "@c/navigation/Item";
import ResultsNavigation from "@c/navigation/Results";
export default {
	name: "LeadNavigation",
	props: {
		expanded: { type: Boolean, default: true }, // is this currently expanded
	},
	computed: {
		organisation() {
			return this.$store.state.organisation.data;
		},
		organisations() {
			return this.$store.state.organisations.data;
		},
		multipleOrganisations() {
			return Object.keys(this.organisations).length > 1;
		},
		allComplete() {
			return this.$store.getters.allComplete;
		},
	},
	data() {
		return {};
	},
	components: {
		NavItem,
		ResultsNavigation,
	},
};
</script>
