import firebase from "firebase";
import Vue from "vue";
export default {
	firestorePath: "users/{userId}",
	firestoreRefType: "doc",
	moduleName: "auth",
	statePropName: "data",
	namespaced: true,
	state: {
		logged_in: false,
		logged_out: false,
	},
	getters: {
		id( state ) {
			return state.data.id;
		},
	},
	serverChange: {
		modifiedHook: function(updateStore, doc, b, {dispatch, state} ) {
			if( doc.organisations && !state.auth.data.organisations  ){
				dispatch("organisations/fetchByIds", doc.organisations, {root: true}).then( () => {
					var id = doc.organisations[0];

					dispatch("organisation/start", id, {root: true} )
				})
			}
			return updateStore(doc);
		}
	},
	sync: {
		defaultValues: {
			status: "published", 
			role: "user"
		}
	},
	mutations: {
		SET_LOGGED_IN(state) {
			Vue.set(state, "logged_in", new Date());
		},
		SET_LOGGED_OUT(state) {
			Vue.set(state, "logged_in", false);
			Vue.set(state, "logged_out", new Date());
		},
	},
	actions: {
		start({ dispatch, commit }) {
			return dispatch("openDBChannel").then(() => {
				// @doc if the user is not an admin, log them out and throw an error
				commit("SET_LOGGED_IN");
				return true;
			});
		},
		stop({ commit, dispatch }) {
			firebase
				.auth()
				.signOut()
				.then(() => {
					commit("SET_LOGGED_OUT");
					dispatch("clearAll", false, { root: true });
					commit("RESET_VUEX_EASY_FIRESTORE_STATE");
					return true;
				});
		},
		addUserDetails({dispatch}){
			var user = {
				displayName: firebase.auth().currentUser.displayName,
				email: firebase.auth().currentUser.email
			}
			dispatch("patch", user )
		}
	},
};
