<template>
	<v-navigation-drawer v-model="drawer" app :mini-variant="mini" permanent style="z-index: 100">
		<logged-in v-if="loggedIn"></logged-in>
		<login v-else></login>
		<v-divider></v-divider>

		<v-list dense>
			<nav-item  :expanded="!mini"  v-for="item in items" :key="item.title" :link="item.link" v-bind="item"> </nav-item>
			<respondent-navigation :expanded="!mini" v-if="isRespondent"></respondent-navigation>
			<lead-navigation :expanded="!mini" v-if="isLead"></lead-navigation>
			<nav-item v-if="isPollee" :expanded="!mini" icon="mdi-crosshairs-question" link="/polls" text="Polls"> </nav-item>
		</v-list>
		<template v-slot:append>
			<v-list dense>
				<start-screen-share-support :expanded="!mini"></start-screen-share-support>
				<nav-item :expanded="!mini" link="/contact" icon="mdi-at" text="Contact Us"></nav-item>
				<chat :expanded="!mini"></chat>
				<logout  :expanded="!mini" v-if="loggedIn"></logout>
				<nav-item @click="mini = !mini" :expanded="!mini" :text="$t('buttons.collapse')" :icon="mini ? 'mdi-chevron-right' : 'mdi-chevron-left'"></nav-item>
			</v-list>
		</template>
	</v-navigation-drawer>
</template>
<script>
import Chat from "@c/ui/Chat.vue";
import LoggedIn from "@c/auth/LoggedIn";
import Login from "@c/auth/Login";
import Logout from "@c/auth/Logout.vue";
import NavItem from "@c/navigation/Item";
import LeadNavigation from "@c/navigation/Lead.vue";
import RespondentNavigation from "@c/navigation/Respondent.vue";
import StartScreenShareSupport from "@c/video-support/StartScreenShareSupport";

export default {
	name: "Sidebar",
	computed: {
		loggedIn() {
			return this.$store.state.auth.logged_in;
		},
		isRespondent() {
			return this.$store.getters.isRespondent;
		},
		isLead() {
			return this.$store.getters.isLead;
		},
		isPollee() {
			return this.$store.getters.isPollee;
		},
	},
	data() {
		return {
			drawer: true,
			items: [
				{ text: "Home", icon: "mdi-home", link: "/" },
				{ text: "About", icon: "mdi-information", link: "/about" },
			],
			mini: false,
		};
	},
	components: {
		Login,
		Chat,
		LoggedIn,
		Logout,
		NavItem,
		LeadNavigation,
		RespondentNavigation,
		StartScreenShareSupport
	},
};
</script>
