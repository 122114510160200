export default {
	messages: {
		hello: "Awright",
		no_questions_found: "No questions in survey",
		completion_rate_less_than_100:
			"This section is {rate}% complete. Click cancel to revisit your answers",
		before_complete:
			"Are you sure? Once you mark this section as complete you will no longer be able to edit your responses.",
		before_revoke:
			"Are you sure? Once you revoke this section, {name} will no longer be able to edit their answers.",
		before_return:
			"Are you sure? Once you return this section, you will no longer be able to edit your answers.",
		no_sections_delegate:
			"You have no active assessment sections available. Please contact your lead respondent",
		no_sections_lead:
			"You have no active assessment sections. Please contact support.",
		currently_delegated:
			"This section is currently delegated and can not be edited",
		section_complete: "This section has been submitted and you can no longer edit your answers"
	},
	auth: {
		login: "Login",
		logged_in: "Logged In",
		logout: "Logout",
	},
	misc: {
		are_you_sure: "Are you sure?",
		email: "Email",
		copied: "Copied",
		saved: "Saved",
		combobox_hint: "Start typing to select or add {item}",
	},
	buttons: {
		collapse: "Collapse Menu",
		cancel: "Cancel",
		save: "Save",
		download: "Download",
		send: "Send",
		delegate: "Delegate",
		submit: "Submit",
		complete: "Complete",
		revoke: "Revoke",
	},
	error: {
		section_disabled: "You can not currently make changes to this section",
		invalid_email: "Invalid email",
	},
	profile: {
		display_name: "Full Name",
	},
	organisation: {
		name: "Organisation Name",
		profile: "Organisation Profile",
		correct_services:
			"Please select the types of service provided by your organisation in order to generate your assessment",
		address: "Address",
		city: "City",
		postcode: "Post Code",
		url: "Website",
		phone: "Main phone",
		logo: "Logo",
		service:
			" Which of the following types of service does your organisation currently provide?",
	},
	staff_survey: {
		title: "Staff Survey",
		intro: "",
		before_copy:
			"The staff survey is an easy way of sharing a pre-defined selection of questions from the Self-Assessment with your organisation's workforce in order to use their responses to inform your own answers to the assessment questions. To use the staff survey feature, simply copy and distribute the link below, for example by email, intranet or any other method you see fit. ",
		after_copy:
			"<p>Questions in the staff survey are pre-selected and cannot be changed to ensure only questions relevant to members of the workforce are included.</p> <p>Members of staff can use the link to access and respond to the survey anonymously. You can view their responses in an aggregated form on this page. </p>",
		thanks: "Thank you for your participation!",
		job_function_text:
			"Please select the job function which most closely matches your own",
	},
	help: {
		help: "Help",
		faq: "Frequently asked questions",
		support: "Support",
		tutorials: "Tutorials",
		privacy: "Privacy and Data Protection",
	},
	assessment: {
		nav: "Assessment",
		start: "Start Assessment",
		complete: "Submit",
		revoke: "Revoke",
		return: "Return",
		progress: "Progress",
		delegate: "Delegate",
		notes: "Notes",
		section_progress: "{section} section progress: {progress}%",
		filter_sections: "Assessment Content Filter",
		before_notes: "You can save a note for this section below. Notes are visible to other with whom you are sharing your assessment with.",
		before_filter:
			"<p>You can use the filter function to find specific assessment questions or sections. You can also use the filter to only show questions you've not yet answered.</p><p>To use the filter, please define your selection criteria below.</p>",
		upload_evidence: "Evidence Upload",
		before_upload_evidence:
			"<p>You can upload evidence you've consulted to answer individual questions or whole sections of the Digital Maturity Assessment to the platform to ensure that anyone reviewing your responses can easily verify your answers. Uploading evidence is also useful to inform your organisation's responses to future iterations of the assessment. </p><p>To upload information, enter a description below and select a file to upload. You can upload as many files as necessary.</p>",
		before_evidence_list: "<h4>Evidence List</h4><p>A list of evidence for the section you've selected is shown below.</p>",
		before_download: "<p>You can download your responses to this section here. Note that all of your responses to the entire assessment will be available online and as a tabular download once you've completed and submitted your organisation's assessment.</p>"
	},
	collaboration: {
		remind: "Send a reminder",
		before_remind:
			"To send a reminder to the email address you have provided for this contact click the arrow below.",
		add_colleague: "Add Colleague",
		colleague_info: "Contact Information",
		before_edit_colleague_info:
			"You can add or edit information for this contact below:",
		fields: {
			user_name: "Colleague name",
		},
		delegation: {
			before_delegate:
				"<p>You can assign whole sections of the assessment to someone else by using the delegate function.</p><p>When they're done, they'll return their delegated section to you for your review. </p><p>To delegate this section, please either select a user below or create a new user by typing their name. ",
		},
		my_team: "My Team",
		polls: {
			title: "Polls",
			thank_you: "Thank you!",
			before_poll:
				"<p>You can share individual questions with others to see how they would answer by using the poll function.</p><p>You'll see a count of answers shown in green underneath each answer option in the questionnaire; and individual poll results are shown below this message once they are received.</p>",
			poll_participants:"<h4>People in this Poll</h4><p>Colleagues included in this poll are shown below, along with their answer once they've responded. </p>",
			add_participant:"<h4>Add Poll Participant</h4><p>To create a poll for this question or to add a participant to a poll, please either select a user below or create a new user by typing their name</p>",
			not_found: "Poll not found.",
			introduction:
				"Bestibulum luctus pretium turpis, ac tristique augue ultrices id. Suspendisse libero purus, porttitor id congue pellentesque, suscipit nec risus. Donec volutpat, neque vel laoreet vehicula, erat dui iaculis sem, in dapibus libero mi eget justo. Fusce accumsan, magna non laoreet lacinia, ligula sem mollis orci, et blandit tellus lorem nec enim. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.",
		},
	},
	results: {
		results: "Results",
		overview: "Overview",
		data: "Data Tables",
		analysis: "Analysis",
		average: "Average",
		section: "Section",
		theme: "Theme",
		question: "Question",
		select_all: "Select All",
	},
	contact_form: {
		message: "Message",
		email: "Email",
		send: "Send",
		after_send: "Thank you. We'll be in touch",
	},
};
