<template>
	<span>
		<nav-item
			v-if="canSupport"
			:expanded="!mini"
			@click="toggle"
			icon="mdi-monitor-share"
			active
			:text="`${sharing ? 'Stop' : 'Start'} Screen Sharing`"
		></nav-item>
		<mw-dialog
			v-model="dialog"
			submitBtn
			@submit="startScreenSharing"
			title
			cancelBtn
			@cancel="stop"
		>
			<template v-slot:dialog-body>
				You are about to start sharing your screen with a member of the DMA
				support team. Are you sure you want to do this?
			</template>
		</mw-dialog>
	</span>
</template>

<script>
import Twilio, { LocalVideoTrack } from "twilio-video";
import NavItem from "@c/navigation/Item";
import videoUtilities from "@/assets/videoUtilities.js";
import MwDialog from "@c/ui/MwDialog";

export default {
	name: "StartScreenShareSupport",
	data: () => ({
		token: null,
		room: null,
		dialog: false,
		sharing: false,
		screen_track: null
	}),
	props: {
		mini: { type: Boolean },
	},
	computed: {
		user() {
			return this.$store.state.auth.data;
		},
		canSupport() {
			return this.user.screenshare_support_available;
		},
		roomName() {
			return `${this.user.id}-support`;
		},
	},
	components: {
		NavItem,
		MwDialog,
	},
	methods: {
		async retrieveToken() {
			const self = this;
			return videoUtilities
				.getToken(this.roomName, this.user.id)
				.then((res) => {
					self.token = res;
					return;
				});
		},
		async getScreenTrack() {
			return navigator.mediaDevices.getDisplayMedia().then((stream) => {
				const screenTrack = stream.getVideoTracks()[0];
				return new LocalVideoTrack(screenTrack);
			});
		},
		endScreensharing() {
			const self = this;
			self.room.localParticipant.unpublishTrack(self.screen_track);
			self.screen_track.stop();
			self.screen_track = null;
		},
		startScreenSharing() {
			const self = this;
			self.getScreenTrack().then((track) => {
				self.screen_track = track;
				self.room.localParticipant.publishTrack(track);
				self.sharing = true;
			});
		},
		toggle() {
			if (this.sharing) {
				this.stop();
			} else {
				this.start();
			}
		},
		start() {
			const self = this;
			self.retrieveToken().then(() => {
				Twilio.connect(self.token, {
					name: self.roomName,
					audio: false,
					video: false,
				}).then((room) => {
					self.room = room;
					room.localParticipant.on("trackStopped", () => {
						self.stop();
					});
					self.dialog = true;
				});
			});
		},
		stop(v) {
			if (!v) {
				this.dialog = false;
				if( this.room ){ 
					this.endScreensharing();
					this.sharing = false;
					this.room.disconnect();
				}
			}
		},
	},
	watch: {
		canSupport(v) {
			if (!v) {
				this.stop();
			}
		},
	},
};
</script>
