<template>
	<v-list-group prepend-icon="mdi-help-circle" v-model="active">
		<template v-slot:activator>
			<v-list-item-title>
				{{ $t("help.help") }}
			</v-list-item-title>
		</template>
		<nav-item :expanded="expanded" link="/help/tutorials" icon="mdi-information-outline" :text="$t('help.tutorials')"></nav-item>
		<nav-item :expanded="expanded" link="/help/faq" :text="$t('help.faq')" icon="mdi-frequently-asked-questions"></nav-item>
		<nav-item :expanded="expanded" link="/help/support" :text="$t('help.support')" icon="mdi-wechat"></nav-item>
		<nav-item :expanded="expanded" link="/help/privacy" :text="$t('help.privacy')" icon="mdi-lock-check"></nav-item>
	</v-list-group>
</template>
<style lang="less">
.v-list-item {
	padding: 0px 10px !important;
}
</style>
<script>
import NavItem from "@c/navigation/Item";
export default {
	name: "ResultsNavigation",
	props: {
		expanded: { type: Boolean, default: true }, // is this currently expanded
	},

	data() {
		return {
			drawer: true,
			active: false,
			mini: false,
		};
	},
	computed: {
		helpPage() {
			return this.$route.path.split("/")[1] == "help";
		},
	},
	watch: {
		helpPage: {
			immediate: true,
			handler(value) {
				this.active = value;
			},
		},
	},
	components: {
		NavItem,
	},
};
</script>
