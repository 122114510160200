<template>
			<div>
        <render-content id="home"></render-content>
        <!-- <v-alert v-if="loggedIn" color="primary" text>
          <render-content id="workshopinfo"></render-content>
        </v-alert> -->
			</div>
</template>

<script>
  import RenderContent from "@c/ui/RenderContent"
  export default {
    name: 'Home',
    components: {
      RenderContent
    },
    computed: {
      loggedIn(){
        return this.$store.state.auth.logged_in
      }
    }
  }
</script>
