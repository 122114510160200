import firebase from "firebase";
export default {
    getToken : async function(roomName, identity ) {
        const functions = firebase.app().functions("europe-west1");
        const getToken = functions.httpsCallable("getVideoToken");
        const credentials = {
            roomName,
            identity
        };
        const result = await getToken(credentials);
        return result.data.token;
    },

}