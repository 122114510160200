export default {
	firestorePath: "emails",
	firestoreRefType: "collection",
	moduleName: "emails",
	statePropName: "data",
	namespaced: true,
	state: {},
	getters: {},
	mutations: {},
	actions: {
		reminder({ rootGetters, dispatch, rootState }, id) {
			let user = rootState.users.data[id];	
			var mail = {
				toUids: [id],
				template: {
					data: {	
						name: user.displayName,
						sender: rootState.auth.data.displayName,
						organisation: rootState.organisation.data.name
					},
					name: "reminder",
				},
				assessment: rootGetters["assessment/id"],
			};
			return dispatch("insert", mail);
		},
		delegate({ rootGetters, dispatch, rootState }, {responseId, userId}) {
			let response = rootState.sectionResponses.data[responseId];
			if( !response ){
				throw new Error("Problem delegating")
			}
			let section = rootState.sections.data[response.section];
			let user = rootState.users.data[userId];

			var mail = {
				toUids: [userId],
				template: {
					data: {
						name: user.displayName,
						sectionName: section.name, 
						sectionIntro: section.introduction, 
						sender: rootState.auth.data.displayName,
						organisation: rootState.organisation.data.name,
					},
					name: "delegate",
				},
				assessment: rootGetters["assessment/id"],
			};
			return dispatch("insert", mail);
		},
		leadRespondent({ rootGetters, dispatch, rootState }, id) {
			let user = rootState.users.data[id];
			var mail = {
				toUids: [id],
				template: {
					data: {
						name: user.displayName,	
						organisation: rootState.organisation.data.name
					},
					name: "leadRespondent",
				},
				assessment: rootGetters["assessment/id"],
			};
			return dispatch("insert", mail);
		},
		contactForm({ rootGetters, dispatch }, { message, email }) {
			let subject = `New contact form from your website: ${email}`;
			var mail = {
				to: "aace@dma.works",
				message: {
					subject: subject,
					html: message,
				},
				type: "contact_form",
				assessment: rootGetters["assessment/id"],
			};
			return dispatch("insert", mail);
		},
		poll({ rootGetters, rootState, dispatch }, id) {
			let poll = rootState.polls.data[id];
			let response = rootState.sectionResponses.data[poll.response];
			let section = rootState.sections.data[response.section];
			let user = rootState.users.data[poll.user];

			let link = `/poll/${id}`;
			var mail = {
				toUids: [poll.user],
				template: {
					data: {
						name: user.displayName,
						sectionName: section.name, 
						sectionIntro: section.introduction.replace(/<[^>]*>?/gm, ''), 
						sender: rootState.auth.data.displayName,
						organisation: rootState.organisation.data.name,
						link
					},
					name: "newpoll",
				},
				assessment: rootGetters["assessment/id"],
			};

			return dispatch("insert", mail);
		},
	},
};
