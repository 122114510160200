export default {
	firestorePath: "sectionResponses",
	firestoreRefType: "collection",
	moduleName: "sectionResponses",
	statePropName: "data",
	namespaced: true,
	sync: {
		defaultValues: {
			multiple: false, 
			status: "not_started", 
			users: {}, 
			completion_rate: 0,
			data: {}
		}
	},
	state: {
	},
	getters: {
	},
	mutations: {
	},
	actions: {
	},
};
