export default {
	firestorePath: "organisations/{id}",
	firestoreRefType: "doc",
	moduleName: "organisation",
	statePropName: "data",
	namespaced: true,
	serverChange: {
		modifiedHook: function(updateStore, doc, _, { dispatch, state }) {
			dispatch(
				"assessments/openDBChannel",
				{ clauses: { where: [["organisation", "==", doc.id]] } },
				{ root: true }
			).then( () => {
				var ids = Object.keys(state.assessments.data)
				dispatch("assessment/start", ids[0], {root: true } )
			});
			return updateStore(doc);
		},
	},
	state: {},
	getters: {
		id(state){
			return state.data.id;
		}
	},
	mutations: {},
	actions: {
		start({dispatch}, id){
			dispatch("openDBChannel", { pathVariables: { id } } )
		}
	},
};
