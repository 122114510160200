import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import firebase from "firebase";
Vue.use(VueRouter);

const routes = [
	{
		path: "/",
		name: "Home",
		component: Home,
	},
	{
		path: "/about",
		name: "About",
		component: () =>
			import(/* webpackChunkName: "about" */ "../views/About.vue"),
	},
	{
		path: "/contribute",
		name: "Contribute",
		component: () =>
			import(/* webpackChunkName: "Contribute" */ "../views/Contribute.vue"),
	},
	{
		path: "/organisation-profile",
		name: "OrganisationProfile",
		props: true,
		meta: {
			requires_auth: true,
			lead: true,
		},
		component: () =>
			import(
				/* webpackChunkName: "organisation-profile" */ "../views/OrganisationProfile.vue"
			),
	},
	{
		path: "/results/overview",
		name: "Results",
		meta: {
			requires_auth: true,
			lead: true,
			complete: true,
		},
		component: () =>
			import(/* webpackChunkName: "results" */ "../views/Results.vue"),
	},
	{
		path: "/results/data",
		name: "Data",
		meta: {
			complete: true,
			requires_auth: true,
			lead: true,
		},
		component: () =>
			import(
				/* webpackChunkName: "data" */ "../components/results/Data.vue"
			),
	},
	{
		path: "/results/analysis",
		name: "Analysis",
		meta: {
			complete: true,
			requires_auth: true,
			lead: true,
		},
		component: () =>
			import(
				/* webpackChunkName: "results" */ "../components/results/Analysis.vue"
			),
	},
	{
		path: "/profile",
		name: "Profile",
		meta: {
			requires_auth: true,
		},
		component: () =>
			import(/* webpackChunkName: "profile" */ "../views/Profile.vue"),
	},
	{
		path: "/contact",
		name: "Contant",
		meta: {
		},
		component: () =>
			import(/* webpackChunkName: "contact" */ "../views/Contact.vue"),
	},
	{
		path: "/help/tutorials",
		name: "Tutorials",
		meta: {
			organisation: true
		},
		component: () =>
			import(/* webpackChunkName: "tutorials" */ "../views/Tutorials.vue"),
	},
	{
		path: "/help/faq",
		name: "Faq",
		meta: {
			organisation: true	
		},
		component: () =>
			import(/* webpackChunkName: "faq" */ "../views/Faq.vue"),
	},
	{
		path: "/help/support",
		name: "Support",
		meta: {
			organisation: true			
		},
		component: () =>
			import(/* webpackChunkName: "support" */ "../views/Support.vue"),
	},
	{
		path: "/help/privacy",
		name: "Privacy",
		meta: {
			organisation: true
		},
		component: () =>
			import(/* webpackChunkName: "privacy" */ "../views/Privacy.vue"),
	},
	{
		path: "/staff-survey-dashboard",
		name: "StaffSurveyDashboard",
		meta: {
			requires_auth: true,
			lead: true,
		},
		component: () =>
			import(
				/* webpackChunkName: "staff-survey-dash" */ "../views/StaffSurveyDashboard.vue"
			),
	},
	{
		path: "/staff-survey/:org",
		name: "StaffSurvey",
		props: true,
		component: () =>
			import(/* webpackChunkName: "staff-survey" */ "../views/StaffSurvey.vue"),
	},
	{
		path: "/report/:id",
		name: "Report",
		props: true,
		meta: {
			requires_auth: true
		},
		component: () =>
			import(/* webpackChunkName: "report" */ "../components/results/report-builder/RenderReport.vue"),
	},
	{
		path: "/my-team",
		name: "AddressBook",
		props: true,
		meta: {
			lead: true
		},
		component: () =>
			import(
				/* webpackChunkName: "profile" */ "../components/collaboration/AddressBook.vue"
			),
	},
	{
		path: "/poll/:id",
		name: "RenderPoll",
		props: true,
		component: () =>
			import(
				/* webpackChunkName: "profile" */ "../components/collaboration/RenderPoll.vue"
			),
	},
	{
		path: "/polls",
		name: "Polls",
		props: true,
		meta: {
			requires_auth: true
		},
		component: () =>
			import(/* webpackChunkName: "profile" */ "../views/Polls.vue"),
	},
	{
		path: "/assessment",
		name: "Assessment",
		meta: {
			requires_auth: true,
			organisation: true
		},
		component: () =>
			import(/* webpackChunkName: "Assessment" */ "../views/Assessment.vue"),
	},
];

const router = new VueRouter({
	mode: "history",
	base: process.env.BASE_URL,
	routes,
});

router.beforeEach(async (to, from, next) => {
	const requiresAuth = to.matched.some((record) => record.meta.requires_auth);
	if (requiresAuth && !(await firebase.getCurrentUser())) {
		next("/");
	} else {
		next();
	}
});

export default router;
