export default {
	firestorePath: "polls",
	firestoreRefType: "collection",
	moduleName: "polls",
	statePropName: "data",
	namespaced: true,
	state: {},
	getters: {
		byQuestion(state, _, rootState) {
			return function(question) {
				return Object.values(state.data).filter((q) => {
					return (
						q.response == rootState.current.response &&
						q.question == question
					);
				});
			};
		},
		myPolls(state, _, __, rootGetters){
			if( !state.data ){
				return [];
			}
			var polls = Object.values(state.data);
			return polls.filter( a => a.user == rootGetters["auth/id"] );
	
		}
	},
	mutations: {},
	actions: {
		myPolls({dispatch, rootGetters}){
			let user = rootGetters["auth/id"];
			return dispatch("openDBChannel", {
				clauses: {
					where: [["user", "==", user]],
				},
			});
		},
		allPolls({ dispatch, rootGetters }) {
			if( !rootGetters["assessment/id"] ){
				return;
			}
			return dispatch("openDBChannel", {
				clauses: {
					where: [["assessment", "==", rootGetters["assessment/id"]]],
				},
			});
		},
		new({ dispatch, rootState }, { question, user }) {
			let poll = {
				response: rootState.current.response,
				assessment: rootState.assessment.data.id,
				question,
				user,
				status: "published",
			};
			return dispatch("insert", poll).then((id) => {
				dispatch("emails/poll", id, { root: true });
			});
		},
	},
};
