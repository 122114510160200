<template>
	<v-list-group prepend-icon="mdi-chart-bar" v-model="active">
		<template v-slot:activator>
			<v-list-item-title>
				{{ $t("results.results") }}
			</v-list-item-title>
		</template>
		<nav-item :expanded="expanded" link="/results/overview" icon="mdi-label-percent-outline" :text="$t('results.overview')"></nav-item>
		<nav-item :expanded="expanded" link="/results/data" :text="$t('results.data')" icon="mdi-table"></nav-item>
		<nav-item :expanded="expanded" link="/results/analysis" :text="$t('results.analysis')" icon="mdi-chart-box-plus-outline"></nav-item>
	</v-list-group>
</template>
<style lang="less">
.v-list-item {
	padding: 0px 10px !important;
}
</style>
<script>
import NavItem from "@c/navigation/Item";
export default {
	name: "ResultsNavigation",
	props: {
		expanded: { type: Boolean, default: true }, // is this currently expanded
	},

	data() {
		return {
			drawer: true,
			active: false,
			mini: false,
		};
	},
	computed: {
		resultsPage() {
			return this.$route.path.split("/")[1] == "results";
		},
	},
	watch: {
		resultsPage: {
			immediate: true,
			handler(value) {
				this.active = value;
			},
		},
	},
	components: {
		NavItem,
	},
};
</script>
