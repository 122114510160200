<template>
	<div>
		<nav-item  :expanded="expanded"  link="/assessment" icon="mdi-tooltip-check" :text="$t('assessment.nav')"></nav-item>
		<help  :expanded="expanded" ></help>
	</div>
</template>
<script>
import NavItem from "@c/navigation/Item";
import Help from "@c/navigation/Help.vue";

export default {
	name: "RespondentNavigation",
	props: {
		expanded: { type: Boolean, default: true }, // is this currently expanded
	},

	computed: {},
	data() {
		return {};
	},
	components: {
		NavItem,
		Help,
	},
};
</script>
