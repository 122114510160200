export default {
	firestorePath: "assessments",
	firestoreRefType: "collection",
	moduleName: "assessments",
	statePropName: "data",
	namespaced: true,
	state: {
	},
	getters: {
	},
	mutations: {
	},
	actions: {
	},
};
