<template>
	<v-app>
		<top-nav></top-nav>
		<sidebar></sidebar>
		<v-main>
			<v-sheet flat class="pa-8">
				<router-view v-if="allowed" />
			</v-sheet>
			
		</v-main>
	</v-app>
</template>

<style lang="less">
	@import url("./assets/css/main.less");
</style>

<script>
import Vuex from "vuex";
import TopNav from "@c/navigation/Top.vue";
import Sidebar from "@c/navigation/Side.vue";
export default {
	name: "App",
	data: () => ({}),
	computed: {
		...Vuex.mapState({
			state: (state) => state,
		}),
		hasOrg() {
			return this.$store.state.organisation.data.id
		},
		isLead() {
			return this.$store.getters.isLead;
		},
		allComplete() {
			return this.$store.getters.allComplete;
		},
		allowed() {
			if (this.$route.meta.organisation && !this.hasOrg) {
				return false;
			}
			if (this.$route.meta.lead && !this.isLead) {
				return false;
			}
			if (this.$route.meta.complete && !this.allComplete) {
				return false;
			}
			return true;
		},
	},
	components: {
		TopNav,
		Sidebar,
	},
	created(){
		document.title = this.$store.getters["config/assessmentTitle"];
	}
};
</script>
