export default {
	firestorePath: "categoryOptions",
	firestoreRefType: "collection",
	moduleName: "categoryOptions",
	statePropName: "data",
	namespaced: true,
    serverChange: {
		
	},
	state: {
	},
	getters: {
	},
	mutations: {
	},
	actions: {
	},
};
