export default {
	firestorePath: "assessments/{id}",
	firestoreRefType: "doc",
	moduleName: "assessment",
	statePropName: "data",
	namespaced: true,
	state: {},
	getters: {
		id(state) {
			return state.data.id;
		},
		// availableResponses(state, _, rootState) {
		// 	return function(section) {
		// 		let organisation = rootState.organisation.data;
		// 		let responses = { ...state.data.responses[section] };
		// 		for (var key in responses) {
		// 			if (!organisation["service"].includes(key)) {
		// 				delete responses[key];
		// 			}
		// 		}
		// 	};
		// },
	},
	mutations: {},
	actions: {
		retrieveMySectionResponses({ rootGetters, state, dispatch }) {
			var id = state.data.id;
			if (!rootGetters.role) {
				return;
			}
			var params = [["assessment", "==", id]];
			if (!rootGetters.isLead) {
				params.push([
					`users.${rootGetters["auth/id"]}`,
					"==",
					rootGetters.role,
				]);
			}
			dispatch(
				"sectionResponses/openDBChannel",
				{ where: params },
				{ root: true }
			);
		},
		start({ dispatch, rootGetters, state }, id) {
			dispatch("openDBChannel", { pathVariables: { id } }).then(() => {
				dispatch("themes/openDBChannel", false, { root: true });
				dispatch("services/openDBChannel", false, { root: true });
				dispatch("config/start", "default", { root: true });
				dispatch("retrieveMySectionResponses");
				if (rootGetters.isLead) {
					dispatch("users/fetchRespondents", state.data.organisation, {
						root: true,
					});
				}
				dispatch("polls/myPolls", false, { root: true });
			});
		},
	},
};
